import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './AuthenticatedBase.module.css';
import { useAuth } from '../../hooks';

export default function AuthenticatedBase(props) {
    const auth = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        if (auth.user === null) {
            navigate('/sign-in');
        } else {
            navigate('/projects');
        }
    }, [auth.user]);

    return (
        <>
            {
                auth.user !== null && props.component
            }
        </>
    );
}