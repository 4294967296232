import React, { useEffect, useState } from 'react';
import styles from './Projects.module.css';
import axios from 'axios';

import { useAuth } from '../../hooks/use-auth';
import ProjectNavbar from '../../components/ProjectNavbar';
import DescribeVideoButton from '../../components/DescribeVideoButton/DescribeVideoButton';
import ProjectItem from '../../components/ProjectItem';
import Sidebar from '../../components/Sidebar/Sidebar';

export default function Projects(props) {
    const auth = useAuth();

    const [projects, setProjects] = useState(null);
    const [isProjectCreationOpen, setIsProjectCreationOpen] = useState(false);

    useEffect(() => {
        if (auth.user && auth.user.email) {
            fetchProjects();
        }
    }, [auth.user]);

    const fetchProjects = () => {
        return new Promise((resolve, reject) => {
            axios.get('/user/projects', {
                params: { user_email: auth.user.email },
                headers: {
                    Authorization: auth.user.authToken
                }
            }).then((response) => {
                setProjects(response.data);
                resolve();
            }).catch((error) => {
                console.error('Error fetching projects:', error);
                reject();
            });
        });
    };

    const toggleOpenProjectCreation = () => {
        setIsProjectCreationOpen(value => !value);
    }

    return (
        <div className={styles.basePage}>
            <ProjectNavbar refresh={fetchProjects} shouldActivateOrdering={isProjectCreationOpen} toggleOrderingState={toggleOpenProjectCreation} />
            <div className={styles.content}>
                <Sidebar selected={1} />
                <main className={styles.mainContent}>
                    <div className={styles.projectListSection}>
                        <h2 className={styles.projectListSectionHeader}>Projects</h2>
                        {
                            projects === null ? (
                                <div className={styles.loadingCircle} />
                            ) : [
                                projects.length === 0 ? (
                                    <DescribeVideoButton key={"describeVideoButton"} buttonText="Describe your first video" onClick={toggleOpenProjectCreation} />
                                ) : (
                                    <div key={"projectsTable"} className={styles.projectsTableWrapper}>
                                        <table className={styles.projectsTable}>
                                            <thead>
                                                <tr>
                                                    <th className={styles.projectsTableStatusColumn}></th>
                                                    <th className={styles.projectsTableNameColumn}>
                                                        <h6 className={styles.projectsTableHeaderText}>Name</h6>
                                                    </th>
                                                    <th className={styles.projectsTableDurationColumn}>
                                                        <h6 className={styles.projectsTableHeaderText}>Duration(s)</h6>
                                                    </th>
                                                    <th className={styles.projectsTableCreatedAtColumn}>
                                                        <h6 className={styles.projectsTableHeaderText}>Created At</h6>
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    projects.map((project) => (
                                                        <ProjectItem key={project["project_uuid"]} project={project} />
                                                    ))
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                )
                            ]
                        }
                    </div>
                </main>
            </div>
        </div>
    );
}