import React, { useEffect, useState } from 'react';
import OrderCreation from '../../../components/ordering/OrderCreation';
import OrderCheckout from '../../../components/ordering/OrderCheckout';
import VideoUploading from '../../../components/ordering/VideoUploading/VideoUploading';
import axios from 'axios';

import { useAuth } from '../../../hooks/use-auth';
import OrderConfirmation from '../../../components/ordering/OrderConfirmation';
import VideoSelection from '../VideoSelection/VideoSelection';

export default function OrderingController(props) {
    const auth = useAuth();

    const [state, setState] = useState(props.orderingState);
    const [customerEmail, setCustomerEmail] = useState(auth.user ? auth.user.email : null);
    const [videoDuration, setVideoDuration] = useState(null);
    const [projectUUID, setProjectUUID] = useState(null);

    const [file, setFile] = useState(props.file);

    const CHUNK_SIZE = 5 * 1024 * 1024; // 5 MB

    useEffect(() => {
        setState(props.orderingState);
    }, [props.orderingState]);

    useEffect(() => {
        if (props.file) {
            setFile(props.file);
            setVideoDuration(null);
            getVideoDuration(props.file);
        }
    }, [props.file]);

    useEffect(() => {
        if (file) {
            setVideoDuration(null);
            getVideoDuration(file);
        }
    }, [file]);

    const getVideoDuration = (file) => {
        if (!file) return;

        // Create a video element
        const video = document.createElement("video");

        // Set the file URL as the video source
        const fileURL = URL.createObjectURL(file);
        video.src = fileURL;

        // Event listener for when metadata is loaded
        video.addEventListener('loadedmetadata', () => {
            setVideoDuration(video.duration); // Duration is in seconds
            URL.revokeObjectURL(fileURL); // Release memory
        });

        video.addEventListener('error', () => {
            console.error("Error loading video metadata");
            URL.revokeObjectURL(fileURL); // Release memory
        });
    };

    const placeOrder = () => {
        return new Promise((resolve, reject) => {
            // Upload video
            uploadVideo(projectUUID, file).then(() => {
                resolve("Successfully uploaded video.");
            }).catch((error) => {
                reject("Failed to upload video.");
            });
        });
    };

    const nextState = (payload) => {
        if (state === "VIDEO_SELECTION") {
            setFile(payload);
            setState("ORDERING");
        } else if (state === "ORDERING") {
            const uuid = crypto.randomUUID();
            setProjectUUID(uuid);
            setCustomerEmail(auth.user === null ? payload["customer_email"] : auth.user["email"]);
            createProject(uuid, file, auth.user === null ? payload["customer_email"] : auth.user["email"]).catch(() => {
                console.log("Failed to create project.");
            });
            setState("ORDER_CHECKOUT");
        } else if (state === "ORDER_CHECKOUT") {
            setState("UPLOADING");
            placeOrder().then(() => {
                if (auth.user) {
                    props.deactivate();
                }
                else {
                    setState("ORDER_CONFIRMATION");
                }
            }).catch((error) => {
                console.log(error);
            });
        } else if (state === "UPLOADING") {
            props.deactivate();
        }
    };

    const uploadVideo = (uuid, file) => {
        return new Promise((resolve, reject) => {        
            const totalChunks = Math.ceil(file.size / CHUNK_SIZE);
            const key = `${uuid}/${uuid}_original_video`;
        
            // Step 1: Initiate the multipart upload
            axios.post('/upload/initiate',
                {
                    key: key,
                    num_parts: totalChunks,
                    content_type: file.type
                }
            ).then(response => {
                const { uploadId, presignedUrls } = response.data;
                uploadChunks(file, presignedUrls).then(parts => {
                    // Step 3: Complete the multipart upload
                    axios.post('/upload/complete',
                        {
                            key: key,
                            upload_id: uploadId,
                            parts: parts,
                        }
                    ).then(response => {
                        console.log('Upload complete!');
                        resolve();
                    }).catch(error => {
                        abortUpload(key, uploadId);
                        reject('Failed to complete the upload: ' + error.message);
                    });
                }).catch((error) => {
                    abortUpload(key, uploadId);
                    reject("Failed to upload file: " + error.message);
                });
            }).catch(error => {
                console.error("Failed to initiate upload", error)
                reject("Failed to initiate upload");
            });
        });
    };

    const uploadChunks = (file, presignedUrls) => {
        return new Promise((resolve, reject) => {
            // Upload each part and collect the ETag
            const uploadPromises = presignedUrls.map((presignedUrl, index) => {
                const chunk = file.slice(index * CHUNK_SIZE, (index + 1) * CHUNK_SIZE);
                return axios({
                    method: "put",
                    url: presignedUrl.url,
                    data: chunk,
                    headers: {
                        "Content-Type": "",
                    }
                }).then(response => {
                    return {
                        PartNumber: index + 1,
                        ETag: response.headers['etag'],
                    };
                }).catch((error) => {
                    console.log(error.response.data);
                });
            });
    
            // Wait for all uploads to complete
            Promise.all(uploadPromises)
                .then(parts => resolve(parts))
                .catch(error => reject(`Failed to upload chunks: ${error.message}`));
        });
    };

    const abortUpload = (key, uploadId) => {
        return new Promise((resolve, reject) => {
            axios.post('/upload/abort',
                {
                    key: key,
                    upload_id: uploadId,
                }
            ).then(() => {
                reject("Aborted multipart upload.");
            }).catch((error) => {
                console.log(error);
                reject("Abort of multipart upload failed.");
            });
        });
    }

    const createProject = (uuid, file, email) => {
        return new Promise((resolve, reject) => {
            // Extract file name without extension
            const fileName = file.name;
            const projectName = fileName.substring(0, fileName.lastIndexOf('.')) || fileName;
        
            // Prepare the API payload
            const payload = {
                project_uuid: uuid,
                project_name: projectName, // Use the file name without extension
                user_email: email,
                file_format: file.type, // Extract file extension
                duration: videoDuration
            };
        
            // Include user_uuid if available
            if (auth.user) {
                payload.user_uuid = auth.user.userUuid;
            }

            axios.post('/project/create', payload, {
                headers: {
                    'Content-Type': 'application/json',
                },
            }).then(() => {
                resolve();
            })
            .catch((error) => {
                console.error('Error creating project:', error);
                reject('Error creating project');
            });
        });
    };

    const exit = (e) => {
        if (e === undefined || e.target === e.currentTarget) {
            props.deactivate();
        }
    };

    return (
        <>
            {
                state === "VIDEO_SELECTION" && <VideoSelection next={nextState} exit={exit} />
            }
            {
                state === "ORDERING" && <OrderCreation url={props.url} file={file} videoDuration={videoDuration} customerEmail={customerEmail} next={nextState} exit={exit} />
            }
            {
                state === "ORDER_CHECKOUT" && <OrderCheckout projectUUID={projectUUID} customerEmail={customerEmail} videoDuration={videoDuration} next={nextState} exit={exit} />
            }
            {
                state === "UPLOADING" && <VideoUploading file={file} next={nextState} />
            }
            {
                state === "ORDER_CONFIRMATION" && <OrderConfirmation exit={exit} />
            }
        </>
    );
}