import React from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './OrderConfirmation.module.css';
import DescribeVideoButton from '../../DescribeVideoButton/DescribeVideoButton';

export default function OrderConfirmation(props) {
    const navigate = useNavigate();

    const handleSubmit = () => {
        navigate('/sign-in');
    };

    return (
        <div className={styles.grayModalBackground} onClick={(e) => props.exit(e)}>
            <div className={styles.orderingBase}>
                <div className={styles.modalHeaderContainer}>
                    <div className={styles.modalHeaderTitle}>
                        <svg  xmlns="http://www.w3.org/2000/svg"  width="60"  height="60"  viewBox="0 0 24 24"  fill="none"  stroke="#3298f1"  strokeWidth="2"  strokeLinecap="round"  strokeLinejoin="round"  className="icon icon-tabler icons-tabler-outline icon-tabler-circle-check">
                            <path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0" />
                            <path d="M9 12l2 2l4 -4" />
                        </svg>
                        <h2 className="black-header-5-text">Thanks for your order!</h2>
                    </div>
                </div>
                <div className={styles.modalContentContainer}>
                    <p className={styles.modalLabel}>We'll send you an email when your described video is ready.</p>
                    <DescribeVideoButton buttonText="Check order status" onClick={handleSubmit} />
                </div>
            </div>
        </div>
    );
}