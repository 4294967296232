import React, { useState } from 'react';
import styles from './UploadVideo.module.css';
import DescribeVideoButton from '../DescribeVideoButton/DescribeVideoButton';

import { useInput } from '../../hooks/use-input';

export default function UploadVideo(props) {
    const [selectedOption, setSelectedOption] = useState('file');
    const [error, setError] = useState(null);

    const { value: url, bind: bindUrl, reset: resetUrl } = useInput("");

    const [file, setFile] = useState();

    const handleOptionChange = (option) => {
        setSelectedOption(option);
        if (error) setError(null);
    };

    const handleFileInput = (e) => {
		setFile(e.target.files[0]);
	};

    const isValidUrl = (value) => {
        try {
            new URL(value);
            return true;
        } catch (error) {
            console.log(error);
            return false;
        }
    };

    const handleSubmit = () => {
        if (selectedOption === 'url') {
            if (!url || !isValidUrl(url)) {
                setError("Please enter a valid URL.");
                return;
            }
            setError(null);
            props.activateOrdering(url, null);
        } else if (selectedOption === 'file') {
            if (!file) {
                setError("Please select a file.");
                return;
            }
            setError(null);
            props.activateOrdering(null, file);
        }
    };

    return (
        <div className={styles.uploadVideoContainer}>
            <div className={styles.segmentedControl}>
                <div className={styles.options}>
                    {/* <button
                        className={`${styles.optionButton} ${selectedOption === 'url' ? styles.active : ''}`}
                        onClick={() => handleOptionChange('url')}
                    >
                        Provide URL
                    </button> */}
                    <button
                        className={`${styles.optionButton} ${selectedOption === 'file' ? styles.active : ''}`}
                        onClick={() => handleOptionChange('file')}
                    >
                        Upload Video
                    </button>
                </div>
                <div className={styles.inputSection}>
                    {/* {selectedOption === 'url' && (
                        <div>
                            <input
                                type="url"
                                id="videoUrl"
                                className={styles.input}
                                placeholder="Enter video URL"
                                {...bindUrl}
                            />
                        </div>
                    )} */}
                    {selectedOption === 'file' && (
                        <input type="file" id="videoFile" className={styles.input} accept=".mp4, .mov" onChange={(e) => handleFileInput(e)} />
                    )}
                    <p className={styles.submitError}>{error}</p>
                </div>
            </div>
            <DescribeVideoButton buttonText="Describe" onClick={handleSubmit}/>
        </div>
    );
}