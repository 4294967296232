import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './Auth.module.css';

import { useAuth, useInput } from '../../hooks';
import Modal from '../../components/Modal/Modal';

export default function VerifyCode(props) {
    const auth = useAuth();
    const navigate = useNavigate();

    const { value: code, bind: bindCode, reset: resetCode } = useInput("");

    const [loading, setLoading] = useState(false);
    
    const [codeError, setCodeError] = useState(null);

    useEffect(() => {
        setCodeError(null);
    }, [code]);

    const handleSubmit = () => {
        var validRegex = /^\d{6}$/;
        if (code === "") {
            setCodeError("Code is required");
            return;
        } else if (!code.match(validRegex)) {
            setCodeError("Not a valid code");
            return;
        }
        setLoading(true);
        auth.handleConfirmSignIn(code).then(() => {
            setLoading(false);
            navigate('/projects');
        }).catch((error) => {
            setLoading(false);
            setCodeError(error);
        });
    };

    const handleEnter = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            handleSubmit();
        }
    };

    return (
        <div className={styles.authBase}>
            <Modal 
                headerAction="BACK_TO_HOME"
                detail={
                    <div className={styles.authForm}>
                        <h2 className="black-header-3-text">We've sent a code to your email</h2>
                        <div className={styles.authInputs}>
                            <div className={styles.authGroup}>
                                <h5 className="black-header-5-text">Your code</h5>
                                <input className={"textInputBox" + (codeError !== null ? " red" : "")} placeholder="Your code" type="text" onKeyDown={(e) => handleEnter(e)} {...bindCode} />
                                {
                                    codeError !== null && (
                                        <div className={styles.authErrorMessage}>
                                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-alert-circle" width="16" height="16" viewBox="0 0 24 24" strokeWidth="2" stroke="#C13515" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                <path d="M3 12a9 9 0 1 0 18 0a9 9 0 0 0 -18 0"></path>
                                                <path d="M12 8v4"></path>
                                                <path d="M12 16h.01"></path>
                                            </svg>
                                            <p className="red-regular-5-text">{codeError}</p>
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                }
                loading={loading}
                actionText={"Continue"}
                action={handleSubmit}
            />
        </div>
    );
}