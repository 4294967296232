import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './Auth.module.css';

import { useAuth, useInput } from '../../hooks';
import Modal from '../../components/Modal/Modal';

export default function SignUp(props) {
    const auth = useAuth();
    const navigate = useNavigate();

    const { value: name, bind: bindName, reset: resetName } = useInput("");
    const { value: email, bind: bindEmail, reset: resetEmail } = useInput("");

    const [loading, setLoading] = useState(false);
    
    const [nameError, setNameError] = useState(null);
    const [emailError, setEmailError] = useState(null);

    useEffect(() => {
            if (auth.user !== null) {
                navigate('/projects');
            }
        }, [auth.user]);

    useEffect(() => {
        setNameError(null);
    }, [name]);

    useEffect(() => {
        setEmailError(null);
    }, [email]);

    const handleSubmit = () => {
        var nameRegex = /^[a-z ,.'-]+$/i;
        var emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        var valid = true;
        if (name === "") {
            setNameError("Your name is required");
            valid = false;
        } else if (!name.match(nameRegex)) {
            setNameError("Not a valid name");
            valid = false;
        }
        if (email === "") {
            setEmailError("Email is required");
            valid = false;
        } else if (!email.match(emailRegex)) {
            setEmailError("Not a valid email");
            valid = false;
        }

        if (valid) {
            setLoading(true);
            auth.handleSignUp(name, email).then(() => {
                auth.handleSignIn(email).then(() => {
                    navigate('/verify-code');
                }).catch((error) => {
                    setLoading(false);
                    setEmailError("Failed to verify your code. Please try again later.");
                })
            }).catch((error) => {
                setLoading(false);
                setEmailError("Failed to sign up. Please try again later.");
            });
        }
    };

    const handleEnter = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            handleSubmit();
        }
    };

    const handleSwitchToSignIn = () => {
        navigate('/sign-in');
    };

    return (
        <div className={styles.authBase}>
            <Modal 
                headerAction="BACK_TO_HOME"
                detail={
                    <div className={styles.authForm}>
                        <h2 className="black-header-3-text">Welcome to Autoscribe</h2>
                        <div className={styles.authInputs}>
                            <div className={styles.authGroup}>
                                <h5 className="black-header-5-text">Your name</h5>
                                <input className={"textInputBox" + (nameError !== null ? " red" : "")} placeholder="Your name" type="text" {...bindName} />
                                {
                                    nameError !== null && (
                                        <div className={styles.authErrorMessage}>
                                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-alert-circle" width="16" height="16" viewBox="0 0 24 24" strokeWidth="2" stroke="#C13515" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                <path d="M3 12a9 9 0 1 0 18 0a9 9 0 0 0 -18 0"></path>
                                                <path d="M12 8v4"></path>
                                                <path d="M12 16h.01"></path>
                                            </svg>
                                            <p className="red-regular-5-text">{nameError}</p>
                                        </div>
                                    )
                                }
                            </div>
                            <div className={styles.authGroup}>
                                <h5 className="black-header-5-text">Your email</h5>
                                <input className={"textInputBox" + (emailError !== null ? " red" : "")} placeholder="Your email" type="text" onKeyDown={(e) => handleEnter(e)} {...bindEmail} />
                                {
                                    emailError !== null && (
                                        <div className={styles.authErrorMessage}>
                                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-alert-circle" width="16" height="16" viewBox="0 0 24 24" strokeWidth="2" stroke="#C13515" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                <path d="M3 12a9 9 0 1 0 18 0a9 9 0 0 0 -18 0"></path>
                                                <path d="M12 8v4"></path>
                                                <path d="M12 16h.01"></path>
                                            </svg>
                                            <p className="red-regular-5-text">{emailError}</p>
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                }
                loading={loading}
                actionText={"Continue"}
                action={handleSubmit}
                secondaryAction={
                    <div className={styles.authSecondaryAction}>
                        <p className="gray-regular-4-text">Already have an account?</p>
                        <p className="blue-regular-4-text" onClick={handleSwitchToSignIn}>Sign in instead</p>
                    </div>
                }
            />
        </div>
    );
}