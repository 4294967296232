import { BrowserRouter, Route, Routes } from 'react-router-dom';
import axios from 'axios';
import { Amplify } from 'aws-amplify';

import { ScrollToTop } from './components';
import { CurrentAuthenticatedUser, SignIn, SignUp, VerifyCode } from './components/Auth';
import { ProvideAuth } from './hooks/use-auth.js';
import AuthenticatedBase from './pages/AuthenticatedBase/AuthenticatedBase';
import Projects from './pages/Projects/Projects';
import Home from './pages/marketing/Home';
import Payments from './pages/Payments/Payments';
import Support from './pages/Support/Support';
import Project from './pages/Project/Project';

Amplify.configure({
	Auth: {
		Cognito: {
			userPoolClientId: '1taldo5s88ln1v1h4m8ns4o4b6',
			userPoolId: 'us-west-2_iNAKh5Ual',
		}
  	}
});
axios.defaults.baseURL = 'https://caemymv0ok.execute-api.us-west-2.amazonaws.com/Prod';

export default function App(props) {
  return (
    <ProvideAuth>
      <BrowserRouter>
        <CurrentAuthenticatedUser />
        <ScrollToTop>
          <Routes>
            <Route path='/' element={<Home />} />
            <Route path='/sign-in' element={<SignIn />} />
            <Route path='/sign-up' element={<SignUp />} />
            <Route path='/verify-code' element={<VerifyCode />} />
            <Route path='/projects' element={<AuthenticatedBase component={<Projects />} />} />
            <Route path='/payments' element={<AuthenticatedBase component={<Payments />} />} />
            <Route path='/support' element={<AuthenticatedBase component={<Support />} />} />
            <Route path='/project/:projectId' element={<AuthenticatedBase component={<Project />} />} />
          </Routes>
        </ScrollToTop>
      </BrowserRouter>
    </ProvideAuth>
	);
}