import { React, useEffect } from 'react';
import { useAuth } from '../../hooks';

export default function CurrentAuthenticatedUser(props) {
    const auth = useAuth();

    useEffect(() => {
        auth.getUser().catch((error) => {
            console.log(error);
        });
    }, []);

    return (<></>);
}