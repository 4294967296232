import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './Auth.module.css';

import { useAuth, useInput } from '../../hooks';
import Modal from '../../components/Modal/Modal';

export default function SignIn(props) {
    const auth = useAuth();
    const navigate = useNavigate();

    const { value: email, bind: bindEmail, reset: resetEmail } = useInput("");

    const [loading, setLoading] = useState(false);

    const [emailError, setEmailError] = useState(null);

    useEffect(() => {
        if (auth.user !== null) {
            navigate('/projects');
        }
    }, [auth.user]);

    useEffect(() => {
        setEmailError(null);
    }, [email]);

    const handleSubmit = () => {
        var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        if (email === "") {
            setEmailError("Email is required");
            return;
        } else if (!email.match(validRegex)) {
            setEmailError("Not a valid email");
            return;
        }
        setLoading(true);
        auth.handleSignIn(email).then(() => {
            setLoading(false);
            navigate('/verify-code');
        }).catch((error) => {
            setLoading(false);
            setEmailError(error);
        });
    };

    const handleEnter = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            handleSubmit();
        }
    };

    const handleSwitchToSignUp = () => {
        navigate('/sign-up');
    };

    return (
        <div className={styles.authBase}>
            <Modal 
                headerAction="BACK_TO_HOME"
                detail={
                    <div className={styles.authForm}>
                        <h2 className="black-header-3-text">Welcome back to Autoscribe</h2>
                        <div className={styles.authInputs}>
                            <div className={styles.authGroup}>
                                <h5 className="black-header-5-text">Your email</h5>
                                <input className={"textInputBox" + (emailError !== null ? " red" : "")} placeholder="Email" type="text" onKeyDown={(e) => handleEnter(e)} {...bindEmail} />
                                {
                                    emailError !== null && (
                                        <div className={styles.authErrorMessage}>
                                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-alert-circle" width="16" height="16" viewBox="0 0 24 24" strokeWidth="2" stroke="#C13515" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                <path d="M3 12a9 9 0 1 0 18 0a9 9 0 0 0 -18 0"></path>
                                                <path d="M12 8v4"></path>
                                                <path d="M12 16h.01"></path>
                                            </svg>
                                            <p className="red-regular-5-text">{emailError}</p>
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                }
                loading={loading}
                actionText={"Continue"}
                action={handleSubmit}
                secondaryAction={
                    <div className={styles.authSecondaryAction}>
                        <p className="gray-regular-4-text">New to Autoscribe?</p>
                        <p className="blue-regular-4-text" onClick={handleSwitchToSignUp}>Create an account</p>
                    </div>
                }
            />
        </div>
    );
}