import React, { useState, useContext, createContext } from 'react';
import { confirmSignIn, fetchAuthSession, getCurrentUser, resendSignUpCode, signIn, signOut, signUp } from '@aws-amplify/auth';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';

const AuthContext = createContext();

export function ProvideAuth({ children }) {
	const auth = useProvideAuth();
	return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
}

export const useAuth = () => {
	return useContext(AuthContext);
}

export function useProvideAuth() {
	const [user, setUser] = useState(null);

	const getUser = () => {
        return new Promise((resolve, reject) => {
            // Fetch the current authenticated user and session
            getCurrentUser().then((currentUser) => {
                fetchAuthSession().then((session) => {
                    // Extract user UUID and idToken
                    const userUUID = currentUser.userId;
                    const idToken = session?.tokens?.idToken?.toString() || "";
            
                    // Validate required fields
                    if (!userUUID || !idToken) {
                        setUser(null);
                        reject("UserUUID or idToken was not present");
                    }
            
                    // Set the default Authorization header for axios
                    // axios.defaults.headers.common['Authorization'] = idToken;
            
                    // Fetch user data from the backend
                    getUserData(userUUID, idToken).then((response) => {
                        var userData = response;
                        userData.authToken = idToken;
                        setUser(userData);
                        resolve(userData);
                    }).catch((error) => {
                        setUser(null);
                        console.log(error);
                        reject(error.message);
                    });
                }).catch((error) => {
                    console.log(error);
                    reject(error.message);
                });
            }).catch((error) => {
				if (error.message == "User needs to be authenticated to call this API.") {
					resolve();
				}
				console.log(error);
				reject(error.message);
            });
        });
    };
		
	const getUserData = (userUUID, authToken) => {
        return new Promise((resolve, reject) => {
			var params = {
				user_uuid: userUUID
			}
            axios.get('/user', {
                params: params,
				headers: {
					Authorization: authToken
				}
            }).then(response => {
                resolve(response.data);
            }).catch(error => {
				console.log(error);
                reject(error);
            });
        });
	};

	const handleSignIn = (username) => {
		return new Promise((resolve, reject) => {
			signIn({
				username,
				options: {
					authFlowType: 'CUSTOM_WITHOUT_SRP'
				}
			}).then(response => {
				resolve();
			}).catch(error => {
				switch (error.message) {
					case "User does not exist.":
						reject("Email not found. Please create an account first.");
						break;
					default:
						reject(error.message);
				}
			});
		});
	};

	const handleSignUp = (name, email) => {
		return new Promise((resolve, reject) => {
			signUp({
				username: uuidv4(),
				password: getRandomString(30),
				options: {
					userAttributes: {
						name: name,
						email: email
					}
				}
			}).then(() => {
				resolve();
			}).catch((error) => {
				switch(error.message) {
					case "PreSignUp failed with error This email already exists..":
						reject("This username is taken. Please try another.");
						break;
					default:
						reject(error.message);
				}
			});
		});
	};

	const handleConfirmSignIn = (challengeResponse) => {
		return new Promise((resolve, reject) => {
			confirmSignIn({ challengeResponse })
				.then((response) => {
					if (response['isSignedIn']) {
						getUser().then((response) => {
							resolve();
						})
					} else {
						reject("Your code was invalid. Please try again.");
					}
				})
				.catch((error) => {
					console.log(error);
					switch (error.code) {
						case "NotAuthorizedException":
							reject("This code has expired. Please try again.");
							break;
						default:
							reject(error);
					}
				});
		});
	};

	const handleResendCode = (username) => {
		return new Promise((resolve, reject) => {
			resendSignUpCode(username).then(() => {
				resolve();
			}).catch((error) => {
				reject(error);
			});
		});
	};

	const handleSignOut = () => {
		return new Promise((resolve, reject) => {
			signOut().then(() => {
				setUser(null);
				resolve();
			}).catch((error) => {
				reject(error);
			});
		});
	};

	return {
		user,
		handleSignIn,
		handleSignUp,
		handleSignOut,
		handleResendCode,
		handleConfirmSignIn,
		getUser
	};
}

function getRandomString(bytes) {
	const randomValues = new Uint8Array(bytes);
	window.crypto.getRandomValues(randomValues);
	return Array.from(randomValues).map(intToHex).join('');
}

function intToHex(nr) {
	return nr.toString(16).padStart(2, '0');
}