import React, { useState } from 'react';
import styles from './Home.module.css';

import MarketingNavbar from '../../components/marketing/MarketingNavbar';
import Footer from '../../components/Footer';
import UploadVideo from '../../components/UploadVideo';
import { OrderingController } from '../../components/ordering/OrderingController';

export default function Home(props) {
    const [orderingState, setOrderingState] = useState("UNSET");
    
    const [url, setUrl] = useState(null);
    const [file, setFile] = useState(null);

    const activateOrdering = (url, file) => {
        if (url !== null) {
            setUrl(url);
        } else if (file !== null) {
            setFile(file);
        }
        setOrderingState("ORDERING");
        document.body.style.overflow = 'hidden';
      };
    
      const deactivateOrdering = () => {
        document.body.style.overflow = 'auto';
        setOrderingState("UNSET");
      };

    return (
        <>
            <OrderingController url={url} file={file} orderingState={orderingState} deactivate={deactivateOrdering} />
            <div className={styles.basePage}>
                <MarketingNavbar />
                <div className={styles.content}>
                    <div className={styles.homeHero}>
                        <div className={styles.homeHeroSection}>
                            <div className={styles.homeHeroTextContainer}>
                                <h1 className={styles.homeHeroText}>Audio description for any video, in minutes.</h1>
                            </div>
                            <UploadVideo activateOrdering={activateOrdering} deactivateOrdering={deactivateOrdering} />
                        </div>
                    </div>
                    <div className={styles.homeSection}>
                        <div className={styles.homeSectionTitle}>
                            <div className={styles.homeSectionTitleBar} />
                            <h6 className={styles.homeSectionTitle}>Why audio description?</h6>
                        </div>
                        <div className={styles.homeSectionContent}>
                            <div className={styles.homeSectionTextContent}>
                                <h2 className={styles.homeSectionHeaderText}>Enable blind and low vision people to consume your videos.</h2>
                                <p className={styles.homeSectionDetailText}>350 million people around the world have moderate to total vision impairment. Without audio descriptions, your videos are inaccessible to this enormous group of people.</p>
                            </div>
                            <div className={styles.cards}>
                                <div className={styles.card}>
                                    <div className={styles.cardTitleContainer}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="icon icon-tabler icons-tabler-outline icon-tabler-world">
                                            <path d="M3 12a9 9 0 1 0 18 0a9 9 0 0 0 -18 0" />
                                            <path d="M3.6 9h16.8" />
                                            <path d="M3.6 15h16.8" />
                                            <path d="M11.5 3a17 17 0 0 0 0 18" />
                                            <path d="M12.5 3a17 17 0 0 1 0 18" />
                                        </svg>
                                        <h4 className={styles.cardTitle}>Accessibility for All</h4>
                                    </div>
                                    <p className={styles.cardText}>Make your videos inclusive for blind and low vision viewers.</p>
                                </div>
                                <div className={styles.card}>
                                    <div className={styles.cardTitleContainer}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="icon icon-tabler icons-tabler-outline icon-tabler-scale">
                                            <path d="M7 20l10 0" />
                                            <path d="M6 6l6 -1l6 1" />
                                            <path d="M12 3l0 17" />
                                            <path d="M9 12l-3 -6l-3 6a3 3 0 0 0 6 0" />
                                            <path d="M21 12l-3 -6l-3 6a3 3 0 0 0 6 0" />
                                        </svg>
                                        <h4 className={styles.cardTitle}>Legal & Ethical Compliance</h4>
                                    </div>
                                    <p className={styles.cardText}>Meet standards like ADA and WCAG to stay compliant and equitable.</p>
                                </div>
                                <div className={styles.card}>
                                    <div className={styles.cardTitleContainer}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="icon icon-tabler icons-tabler-outline icon-tabler-chart-bar-popular">
                                            <path d="M3 13a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v6a1 1 0 0 1 -1 1h-4a1 1 0 0 1 -1 -1z" />
                                            <path d="M9 9a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v10a1 1 0 0 1 -1 1h-4a1 1 0 0 1 -1 -1z" />
                                            <path d="M15 5a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v14a1 1 0 0 1 -1 1h-4a1 1 0 0 1 -1 -1z" />
                                            <path d="M4 20h14" />
                                        </svg>
                                        <h4 className={styles.cardTitle}>Reach a Wider Audience</h4>
                                    </div>
                                    <p className={styles.cardText}>Expand your reach by creating accessible, user-friendly content.</p>
                                </div>
                                <div className={styles.card}>
                                    <div className={styles.cardTitleContainer}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="icon icon-tabler icons-tabler-outline icon-tabler-trophy">
                                            <path d="M8 21l8 0" />
                                            <path d="M12 17l0 4" />
                                            <path d="M7 4l10 0" />
                                            <path d="M17 4v8a5 5 0 0 1 -10 0v-8" />
                                            <path d="M5 9m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0" />
                                            <path d="M19 9m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0" />
                                        </svg>
                                        <h4 className={styles.cardTitle}>Positive Reputation</h4>
                                    </div>
                                    <p className={styles.cardText}>Show your commitment to inclusivity and stand out as a responsible brand.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={styles.homeSection}>
                        <div className={styles.homeSectionTitle}>
                            <div className={styles.homeSectionTitleBar} />
                            <h6 className={styles.homeSectionTitle}>Why Autoscribe?</h6>
                        </div>
                        <div className={styles.homeSectionContent}>
                            <div className={styles.homeSectionTextContent}>
                                <h2 className={styles.homeSectionHeaderText}>Our mission is to make every video accessible to everyone.</h2>
                                <p className={styles.homeSectionDetailText}>Making audio description prevalent requires a scalable, affordable, and high-quality solution. By iterating quickly and working closely with the blind and low vision community, Autoscribe exists to build this solution.</p>
                            </div>
                            <div className={styles.cards}>
                                <div className={styles.card}>
                                    <div className={styles.cardTitleContainer}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="icon icon-tabler icons-tabler-outline icon-tabler-robot">
                                            <path d="M6 4m0 2a2 2 0 0 1 2 -2h8a2 2 0 0 1 2 2v4a2 2 0 0 1 -2 2h-8a2 2 0 0 1 -2 -2z" />
                                            <path d="M12 2v2" />
                                            <path d="M9 12v9" />
                                            <path d="M15 12v9" />
                                            <path d="M5 16l4 -2" />
                                            <path d="M15 14l4 2" />
                                            <path d="M9 18h6" />
                                            <path d="M10 8v.01" />
                                            <path d="M14 8v.01" />
                                        </svg>
                                        <h4 className={styles.cardTitle}>AI-Powered Efficiency</h4>
                                    </div>
                                    <p className={styles.cardText}>Leverage the latest in AI to get descriptions generated in minutes.</p>
                                </div>
                                <div className={styles.card}>
                                    <div className={styles.cardTitleContainer}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="icon icon-tabler icons-tabler-outline icon-tabler-moneybag">
                                            <path d="M9.5 3h5a1.5 1.5 0 0 1 1.5 1.5a3.5 3.5 0 0 1 -3.5 3.5h-1a3.5 3.5 0 0 1 -3.5 -3.5a1.5 1.5 0 0 1 1.5 -1.5z" />
                                            <path d="M4 17v-1a8 8 0 1 1 16 0v1a4 4 0 0 1 -4 4h-8a4 4 0 0 1 -4 -4z" />
                                        </svg>
                                        <h4 className={styles.cardTitle}>Cost-Effective</h4>
                                    </div>
                                    <p className={styles.cardText}>Industry-leading prices, powered by our automated solutions.</p>
                                </div>
                                <div className={styles.card}>
                                    <div className={styles.cardTitleContainer}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="icon icon-tabler icons-tabler-outline icon-tabler-bolt">
                                            <path d="M13 3l0 7l6 0l-8 11l0 -7l-6 0l8 -11" />
                                        </svg>
                                        <h4 className={styles.cardTitle}>Scalable & Fast</h4>
                                    </div>
                                    <p className={styles.cardText}>Whether you have one video or thousands, our platform scales effortlessly.</p>
                                </div>
                                <div className={styles.card}>
                                    <div className={styles.cardTitleContainer}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="icon icon-tabler icons-tabler-outline icon-tabler-sparkles">
                                            <path d="M16 18a2 2 0 0 1 2 2a2 2 0 0 1 2 -2a2 2 0 0 1 -2 -2a2 2 0 0 1 -2 2zm0 -12a2 2 0 0 1 2 2a2 2 0 0 1 2 -2a2 2 0 0 1 -2 -2a2 2 0 0 1 -2 2zm-7 12a6 6 0 0 1 6 -6a6 6 0 0 1 -6 -6a6 6 0 0 1 -6 6a6 6 0 0 1 6 6z" />
                                        </svg>
                                        <h4 className={styles.cardTitle}>High Quality</h4>
                                    </div>
                                    <p className={styles.cardText}>Advanced algorithms ensure accurate and context-aware descriptions.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={styles.homeSection}>
                        <div className={styles.homeSectionTitle}>
                            <div className={styles.homeSectionTitleBar} />
                            <h6 className={styles.homeSectionTitle}>How does it work?</h6>
                        </div>
                        <div className={styles.homeSectionContent}>
                            <div className={styles.homeSectionTextContent}>
                                <h2 className={styles.homeSectionHeaderText}>The power of artificial intelligence, applied to accessibility.</h2>
                                <p className={styles.homeSectionDetailText}>Our flagship model, ad-v2, is a state-of-the-art video and audio processing engine, powered by the latest AI, capable of crafting high-quality audio descriptions.</p>
                            </div>
                            <div className={styles.cards}>
                                <div className={styles.smallCard}>
                                    <div className={styles.cardTitleContainer}>
                                        <h4 className={styles.cardTitle}>1. Upload</h4>
                                    </div>
                                    <p className={styles.cardText}>Securely upload your video, in any video format, via our website.</p>
                                </div>
                                <div className={styles.smallCard}>
                                    <div className={styles.cardTitleContainer}>
                                        <h4 className={styles.cardTitle}>2. Analyze</h4>
                                    </div>
                                    <p className={styles.cardText}>Our AI identifies key visual elements in each scene and writes descriptive sentences.</p>
                                </div>
                                <div className={styles.smallCard}>
                                    <div className={styles.cardTitleContainer}>
                                        <h4 className={styles.cardTitle}>3. Text to Speech</h4>
                                    </div>
                                    <p className={styles.cardText}>We use OpenAI's text-to-speech models to read the generated descriptions aloud.</p>
                                </div>
                                <div className={styles.smallCard}>
                                    <div className={styles.cardTitleContainer}>
                                        <h4 className={styles.cardTitle}>4. Scheduling</h4>
                                    </div>
                                    <p className={styles.cardText}>Optimally positioning descriptions to avoid clashing with speech in the video.</p>
                                </div>
                                <div className={styles.smallCard}>
                                    <div className={styles.cardTitleContainer}>
                                        <h4 className={styles.cardTitle}>5. Mixing</h4>
                                    </div>
                                    <p className={styles.cardText}>Seamlessly combining the original audio track with each description track to produce the final result.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={styles.homeSection}>
                        <div className={styles.homeSectionTitle}>
                            <div className={styles.homeSectionTitleBar} />
                            <h6 className={styles.homeSectionTitle}>Pricing</h6>
                        </div>
                        <div className={styles.homeSectionContent}>
                            <div className={styles.homeSectionTextContent}>
                                <h2 className={styles.homeSectionHeaderText}>$5 per video minute</h2>
                                <p className={styles.homeSectionDetailText}>A simple, flat rate that works for most orders. For bulk orders, email us at autoscribeco@gmail.com.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </>
    );
}