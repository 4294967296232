import React from 'react';
import styles from './DescribeVideoButton.module.css';

export default function DescribeVideoButton(props) {
    return (
        <button className={styles.describeVideoButton} onClick={props.onClick}>
            <p className={styles.describeVideoButtonText}>{props.buttonText}</p>
            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-chevron-right" width="16" height="16" viewBox="0 0 24 24" strokeWidth="3" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                <path d="M9 6l6 6l-6 6"></path>
            </svg>
        </button>
    )
}