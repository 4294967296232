import React from 'react';
import styles from './Footer.module.css'

export default function Footer(props) {
    return (
        <footer className={styles.footer}>
            <div className={styles.footerContent}>
                {/* Logo and Branding */}
                <div className={styles.logoContainer}>
                    <div className={styles.logo}>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="icon icon-tabler icon-tabler-feather"
                            width="27"
                            height="27"
                            viewBox="0 0 24 24"
                            strokeWidth="1.5"
                            stroke="#FFFFFF"
                            fill="none"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        >
                            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                            <path d="M4 20l10 -10m0 -5v5h5m-9 -1v5h5m-9 -1v5h5m-5 -5l4 -4l4 -4"></path>
                            <path d="M19 10c.638 -.636 1 -1.515 1 -2.486a3.515 3.515 0 0 0 -3.517 -3.514c-.97 0 -1.847 .367 -2.483 1m-3 13l4 -4l4 -4"></path>
                        </svg>
                    </div>
                    <h5 className={styles.brandName}>Autoscribe</h5>
                </div>
            </div>
        </footer>
    );
}