import React, { useEffect, useState } from 'react';
import styles from './OrderCreation.module.css';
import DescribeVideoButton from '../../DescribeVideoButton/DescribeVideoButton';

import { useInput } from '../../../hooks/use-input';

export default function OrderCreation(props) {
    const { value: url, bind: bindUrl, reset: resetUrl } = useInput(props.url);
    const { value: email, bind: bindEmail, reset: resetEmail } = useInput(props.customerEmail ? props.customerEmail : "");

    const [urlError, setURLError] = useState(null);
    const [emailError, setEmailError] = useState(null);

    const isValidUrl = (value) => {
        try {
            new URL(value);
            return true;
        } catch (error) {
            console.log(error);
            return false;
        }
    };

    const isValidEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const formatTime = (seconds) => {
        // Round to the nearest whole second
        const totalSeconds = Math.round(seconds);
    
        // Calculate hours, minutes, and remaining seconds
        const hours = Math.floor(totalSeconds / 3600);
        const minutes = Math.floor((totalSeconds % 3600) / 60);
        const remainingSeconds = totalSeconds % 60;
    
        // Construct the result string
        const timeParts = [];
        if (hours > 0) {
            timeParts.push(`${hours}h`);
        }
        if (minutes > 0) {
            timeParts.push(`${minutes}m`);
        }
        if (remainingSeconds > 0 || timeParts.length === 0) {
            timeParts.push(`${remainingSeconds}s`);
        }
    
        return timeParts.join(" ");
    }

    const handleSubmit = () => {
        if (url !== null && url !== undefined) {
            if (!isValidUrl(url)) {
                setURLError("Please enter a valid URL.");
                return;
            }
            setURLError(null);
        }
        if (!isValidEmail(email)) {
            setEmailError("Please enter a valid email.");
            return;
        }
        setEmailError(null);
        props.next(
            {
                "url": url,
                "file": props.file,
                "customer_email": email.toLowerCase()
            }
        );
    };

    return (
        <div className={styles.grayModalBackground} onClick={(e) => props.exit(e)}>
            <div className={styles.orderingBase}>
                <div className={styles.modalHeaderContainer}>
                    <div className={styles.modalClose} onClick={() => props.exit()}>
                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-x" width="20" height="20" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#000000" fill="none" strokeLinecap="round" strokeLinejoin="round" preserveAspectRatio="none">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                            <line x1="18" y1="6" x2="6" y2="18" />
                            <line x1="6" y1="6" x2="18" y2="18" />
                        </svg>
                    </div>
                    <div className={styles.modalHeaderTitle}>
                        <h2 className="black-header-5-text">Get your video described in minutes</h2>
                    </div>
                </div>
                <div className={styles.modalContentContainer}>
                    {
                        props.url !== null && props.url !== undefined && (
                            <div className={styles.modalField}>
                                <p className={styles.modalFieldLabel}>Video URL</p>
                                <input
                                    type="url"
                                    id="videoUrl"
                                    className={styles.input}
                                    placeholder="Enter video URL"
                                    {...bindUrl}
                                />
                                {
                                    urlError !== null && <p className={styles.submitError}>{urlError}</p>
                                }
                            </div>
                        )
                    }
                    {
                        props.file !== null && props.file !== undefined && (
                            <div className={styles.modalField}>
                                <p className={styles.modalFieldLabel}>Video File</p>
                                <p className={styles.modalFieldText}>{props.file.name}</p>
                            </div>
                        )
                    }
                    <div className={styles.modalField}>
                        <p className={styles.modalFieldLabel}>Video Duration</p>
                        {
                            props.videoDuration === null ? (
                                <div className={styles.loadingCircle} />
                            ) : (
                                <p className={styles.modalFieldText}>{formatTime(props.videoDuration)}</p>
                            )
                        }
                    </div>
                    <div className={styles.modalField}>
                        <p className={styles.modalFieldLabel}>Your email</p>
                        <p className={styles.modalFieldSublabel}>We'll notify this email when your video is ready.</p>
                        <input
                            type="email"
                            id="userEmail"
                            className={styles.input}
                            placeholder="Enter your email"
                            {...bindEmail}
                        />
                        {
                            emailError !== null && <p className={styles.submitError}>{emailError}</p>
                        }
                    </div>
                </div>
                {
                    props.loading ? (
                        <div className="full-width-button disabled">
                            <div className={styles.loadingCircle} />
                        </div>
                    ) : (
                        <DescribeVideoButton buttonText="Proceed to payment" onClick={handleSubmit} />
                    )
                }
            </div>
        </div>
    );
}