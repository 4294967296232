import React, { useEffect, useState } from 'react';
import styles from './Payments.module.css';
import axios from 'axios';

import { useAuth } from '../../hooks/use-auth';
import ProjectNavbar from '../../components/ProjectNavbar';
import Sidebar from '../../components/Sidebar/Sidebar';
import PaymentItem from '../../components/PaymentItem';

export default function Payments(props) {
    const auth = useAuth();

    const [payments, setPayments] = useState(null);

    useEffect(() => {
        fetchPayments(null, null);
    }, []);

    const fetchPayments = (starting_after, ending_before) => {
        return new Promise((resolve, reject) => {
            var params = {
                customer_id: auth.user.stripe_customer_id,
                user_email: auth.user.email
            }
            if (starting_after !== null) {
                params['starting_after'] = starting_after
            }
            if (ending_before !== null) {
                params['ending_before'] = ending_before
            }
            axios.get('/user/payments', {
                params: params,
                headers: {
                    Authorization: auth.user.authToken
                }
            }).then((response) => {
                setPayments(response.data);
                resolve();
            }).catch((error) => {
                console.log(error);
                setPayments([]);
                // reject("Failed to retrieve payments.");
            });
        });
    };

    return (
        <div className={styles.basePage}>
            <ProjectNavbar />
            <div className={styles.content}>
                <Sidebar selected={2} />
                <main className={styles.mainContent}>
                    <div className={styles.paymentsSection}>
                        <h2 className={styles.paymentsSectionHeader}>Payments</h2>
                        {
                            payments === null ? (
                                <div className={styles.loadingCircle} />
                            ) : [
                                payments.length === 0 ? (
                                    <p key={"None found"} className={styles.noPaymentsText}>No payments found.</p>
                                ) : (
                                    <table key={"paymentsTable"} className={styles.paymentsTable}>
                                        <thead>
                                            <tr>
                                                <th className={styles.paymentsTableHeaderColumn}>
                                                    <div className={styles.paymentsTableAmountColumn}>
                                                        <h6 className={styles.paymentsTableHeaderText}>Amount</h6>
                                                    </div>
                                                </th>
                                                <th className={styles.paymentsTableHeaderColumn}>
                                                    <div className={styles.paymentsTableCurrencyColumn} />
                                                </th>
                                                <th className={styles.paymentsTableHeaderColumn}>
                                                    <div className={styles.paymentsTableStatusColumn} />
                                                </th>
                                                <th className={styles.paymentsTableHeaderColumn}>
                                                    <div className={styles.paymentsTableProjectNameColumn}>
                                                        <h6 className={styles.paymentsTableHeaderText}>Project name</h6>
                                                    </div>
                                                </th>
                                                <th className={styles.paymentsTableHeaderColumn}>
                                                    <div className={styles.paymentsTableDurationColumn}>
                                                        <h6 className={styles.paymentsTableHeaderText}>Video duration</h6>
                                                    </div>
                                                </th>
                                                <th className={styles.paymentsTableHeaderColumn}>
                                                    <div className={styles.paymentsTableHeaderColumnContent}>
                                                        <h6 className={styles.paymentsTableHeaderText}>Payment method</h6>
                                                    </div>
                                                </th>
                                                <th className={styles.paymentsTableHeaderColumn}>
                                                    <div className={styles.paymentsTableHeaderColumnContent}>
                                                        <h6 className={styles.paymentsTableHeaderText}>Date</h6>
                                                    </div>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                payments.map((payment) => (
                                                    <PaymentItem key={payment["id"]} payment={payment} />
                                                ))
                                            }
                                        </tbody>
                                    </table>
                                )
                            ]
                        }
                    </div>
                </main>
            </div>
        </div>
    );
}