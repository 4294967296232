import React, { useCallback, useState } from 'react';
import styles from './OrderCheckout.module.css';
import axios from 'axios';

import {
    EmbeddedCheckoutProvider,
    EmbeddedCheckout
  } from '@stripe/react-stripe-js';
  import {loadStripe} from '@stripe/stripe-js';
import { useAuth } from '../../../hooks';

const stripePromise = loadStripe("pk_live_51QgHI0AkJfBT9uaiNOx3eApy5FX71yi8W7CJh89Ma73HRObca6yDW3l69i5dFSzTovLW8duhlRhovwzvb8ackqDH00s3opKtxf");

const secondsToNearestMinute = (seconds) => {
    // Convert seconds to minutes and round up
    return Math.ceil(seconds / 60);
}

const CheckoutForm = ({ projectUUID, customerEmail, videoDuration, stripeCustomerId, onCompletion }) => {
    const fetchClientSecret = useCallback(() => {
        // Create a Checkout Session
        return axios.post(
            "/create-checkout-session",
            {
                project_uuid: projectUUID,
                quantity: secondsToNearestMinute(videoDuration),
                customer_email: customerEmail,
                stripe_customer_id: stripeCustomerId
            },
            {
                headers: {
                    "Content-Type": "application/json"
                },
            }
        )
        .then((res) => res.data.clientSecret);
    }, []);

    const options = {fetchClientSecret};

    const handleComplete = () => onCompletion();
    
    return (
        <div id="checkout">
        <EmbeddedCheckoutProvider
            stripe={stripePromise}
            options={{
                ...options,
                onComplete: handleComplete
            }}
        >
            <EmbeddedCheckout />
        </EmbeddedCheckoutProvider>
        </div>
    );
}

export default function OrderCheckout(props) {
    const auth = useAuth();

    const stripeCustomerId = auth.user ? auth.user.stripe_customer_id : null;

    const handleCompletion = () => {
        props.next();
    };

    return (
        <div className={styles.grayModalBackground}>
            <div className={styles.orderingBase}>
                <div className={styles.modalHeaderContainer}>
                    <div className={styles.modalClose} onClick={() => props.exit()}>
                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-x" width="20" height="20" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#000000" fill="none" strokeLinecap="round" strokeLinejoin="round" preserveAspectRatio="none">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                            <line x1="18" y1="6" x2="6" y2="18" />
                            <line x1="6" y1="6" x2="18" y2="18" />
                        </svg>
                    </div>
                    <div className={styles.modalHeaderTitle}>
                        <h2 className="black-header-5-text">Get your video described in minutes</h2>
                    </div>
                </div>
                <div className={styles.modalContentContainer}>
                    <CheckoutForm projectUUID={props.projectUUID} customerEmail={props.customerEmail} videoDuration={props.videoDuration} stripeCustomerId={stripeCustomerId} onCompletion={handleCompletion} />
                </div>
            </div>
        </div>
    );
}