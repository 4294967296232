import React, { useEffect, useState } from 'react';
import { useLocation, useParams, useNavigate } from 'react-router-dom';
import styles from './Project.module.css';
import axios from 'axios';

import { useAuth } from '../../hooks/use-auth';
import ProjectNavbar from '../../components/ProjectNavbar';
import Sidebar from '../../components/Sidebar/Sidebar';

export default function Project(props) {
    const auth = useAuth();
    const location = useLocation();
    const { projectId } = useParams();
    const navigate = useNavigate();
    const state = location.state || null;

    const [project, setProject] = useState(null);
    const [videoURL, setVideoURL] = useState(null);

    useEffect(() => {
        if (state !== null) {
            setProject(state.project);
            fetchVideoURL(state.project);
        } else if (auth.user && auth.user.email) {
            fetchProject().then((project) => {
                fetchVideoURL(project).catch((error) => {
                    console.log(error);
                });
            }).catch((error) => {
                console.log(error);
            });
        }
    }, [auth.user, state]);

    const fetchProject = () => {
        return new Promise((resolve, reject) => {
            axios.get('/project', {
                params: {project_id: projectId },
                headers: {
                    Authorization: auth.user.authToken
                }
            }).then((response) => {
                setProject(response.data);
                resolve(response.data);
            }).catch((error) => {
                console.error('Error fetching projects:', error);
                reject("Failed to fetch project.");
            });
        });
    };

    const fetchVideoURL = (project) => {
        return new Promise((resolve, reject) => {
            axios.get('/project/video', {
                params: {
                    project_uuid: project.project_uuid,
                    content_type: 'described_video'
                },
                headers: {
                    Authorization: auth.user.authToken
                }
            }).then((response) => {
                setVideoURL(response.data.presigned_url);
                resolve();
            }).catch((error) => {
                console.error('Error fetching video URL:', error);
                reject("Failed to fetch video URL.");
            });
        });
    };

    const handleBackClick = () => {
        navigate('/projects');
    };

    const downloadVideo = (url, filename) => {
        if (!url) {
            console.error("Video URL is missing");
            return;
        }
        
        fetch(url)
            .then(response => response.blob())
            .then(blob => {
                const link = document.createElement("a");
                link.href = URL.createObjectURL(blob);
                link.download = filename || "video.mp4";  // Default to .mp4
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            })
            .catch(error => console.error("Error downloading the video:", error));
    };

    return (
        <div className={styles.basePage}>
            <ProjectNavbar />
            <div className={styles.content}>
                <Sidebar selected={0} />
                <main className={styles.mainContent}>
                    <div className={styles.actionsBar}>
                        <div className={styles.backButton} onClick={handleBackClick}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="#3298F1" strokeWidth="3"  strokeLinecap="round" strokeLinejoin="round" className="icon icon-tabler icons-tabler-outline icon-tabler-chevron-left">
                                <path d="M15 6l-6 6l6 6" />
                            </svg>
                            <h6 className={styles.backButtonText}>Back</h6>
                        </div>
                        {
                            project !== null && (
                                <button className={styles.downloadButton} onClick={() => downloadVideo(videoURL, project.project_name)}>
                                    Download
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="#FFFFFF" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" className="icon icon-tabler icons-tabler-outline icon-tabler-download">
                                        <path d="M4 17v2a2 2 0 0 0 2 2h12a2 2 0 0 0 2 -2v-2" />
                                        <path d="M7 11l5 5l5 -5" />
                                        <path d="M12 4l0 12" />
                                    </svg>
                                </button>
                            )
                        }
                    </div>
                    <div className={styles.projectSection}>
                        {
                            project === null ? (
                                <div className={styles.loadingNamePlaceholder}>
                                    <div className={styles.loadingGradient}></div>
                                </div>
                            ) : (
                                <h2 className={styles.projectSectionHeader}>{project.project_name}</h2>
                            )
                        }
                        {
                            project === null ? (
                                <div className={styles.loadingVideoPlaceholder}>
                                    <div className={styles.loadingGradient}></div>
                                </div>
                            ) : (
                                <div className={styles.videoPlayerContainer}>
                                    <video className={styles.videoPlayer} controls>
                                        <source src={videoURL} type={project.file_format} />
                                        Your browser does not support the video tag.
                                    </video>
                                </div>
                            )
                        }
                    </div>
                </main>
            </div>
        </div>
    );
}