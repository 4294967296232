import React from 'react';
import styles from './VideoUploading.module.css';

export default function VideoUploading(props) {
    return (
        <div className={styles.grayModalBackground}>
            <div className={styles.orderingBase}>
                <div className={styles.modalHeaderContainer}>
                    <div className={styles.modalHeaderTitle}>
                        <h2 className="black-header-5-text">Uploading your video...</h2>
                    </div>
                </div>
                <div className={styles.modalContentContainer}>
                    <div className={styles.loadingCircle} />
                    <p className={styles.modalLabel}>Do not exit this page or refresh.</p>
                </div>
            </div>
        </div>
    );
}