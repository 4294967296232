import React from 'react';
import styles from './PaymentItem.module.css';

export default function PaymentItem(props) {
    const formatAmount = (amount) => {
        if (typeof amount !== 'number') {
            throw new Error("Amount must be a number");
        }
    
        // Convert cents to dollars and format as currency
        return `$${(amount / 100).toFixed(2)}`;
    };

    const formatStatus = (status) => {
        switch (status) {
            case 'succeeded':
                return (
                    <div className={styles.succeededStatus}>
                        <p className={styles.succeededStatusText}>Succeeded</p>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="#0e610e" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="icon icon-tabler icons-tabler-outline icon-tabler-check">
                            <path d="M5 12l5 5l10 -10" />
                        </svg>
                    </div>
                )
        
            default:
                break;
        }
    };

    const formatDuration = (seconds) => {
        // Ensure the input is a number and not negative
        if (typeof seconds !== 'number' || seconds < 0) {
            throw new Error("Input must be a non-negative number");
        }
    
        // Round to the nearest second
        const roundedSeconds = Math.round(seconds);
    
        // Calculate hours, minutes, and seconds
        const hours = Math.floor(roundedSeconds / 3600);
        const minutes = Math.floor((roundedSeconds % 3600) / 60);
        const secs = roundedSeconds % 60;
    
        // Format as "HH:MM:ss" with leading zeros
        return [
            hours.toString().padStart(2, '0'),
            minutes.toString().padStart(2, '0'),
            secs.toString().padStart(2, '0')
        ].join(':');
    };

    const formatPaymentMethod = (paymentMethod) => {
        return (
            <div className={styles.paymentMethodContainer}>
                {
                    formatPaymentMethodBrand(paymentMethod.display_brand)
                }
                <div className={styles.paymentMethodDotsNumbers}>
                    <div className={styles.paymentMethodDots}>
                        {[...Array(4)].map((_, index) => (
                            <svg key={index} xmlns="http://www.w3.org/2000/svg" width="4" height="4" viewBox="0 0 24 24" fill="#636363" className="icon icon-tabler icons-tabler-filled icon-tabler-circle">
                                <path d="M7 3.34a10 10 0 1 1 -4.995 8.984l-.005 -.324l.005 -.324a10 10 0 0 1 4.995 -8.336z" />
                            </svg>
                        ))}
                    </div>
                    <p className={styles.regularText}>{paymentMethod.last4}</p>
                </div>
            </div>
        );
    };

    const formatPaymentMethodBrand = (brand) => {
        switch (brand) {
            case 'visa':
                return (
                    <svg className="SVGInline-svg SVGInline--cleaned-svg SVG-svg BrandIcon-svg BrandIcon--size--20-svg" height="20" width="20" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
                        <g fill="none" fillRule="evenodd">
                            <path d="M0 0h32v32H0z" fill="#00579f" />
                            <g fill="#fff" fillRule="nonzero">
                                <path d="M13.823 19.876H11.8l1.265-7.736h2.023zm7.334-7.546a5.036 5.036 0 0 0-1.814-.33c-1.998 0-3.405 1.053-3.414 2.56-.016 1.11 1.007 1.728 1.773 2.098.783.379 1.05.626 1.05.963-.009.518-.633.757-1.216.757-.808 0-1.24-.123-1.898-.411l-.267-.124-.283 1.737c.475.213 1.349.403 2.257.411 2.123 0 3.505-1.037 3.521-2.641.008-.881-.532-1.556-1.698-2.107-.708-.354-1.141-.593-1.141-.955.008-.33.366-.667 1.165-.667a3.471 3.471 0 0 1 1.507.297l.183.082zm2.69 4.806.807-2.165c-.008.017.167-.452.266-.74l.142.666s.383 1.852.466 2.239h-1.682zm2.497-4.996h-1.565c-.483 0-.85.14-1.058.642l-3.005 7.094h2.123l.425-1.16h2.597c.059.271.242 1.16.242 1.16h1.873zm-16.234 0-1.982 5.275-.216-1.07c-.366-1.234-1.515-2.575-2.797-3.242l1.815 6.765h2.14l3.18-7.728z" />
                                <path d="M6.289 12.14H3.033L3 12.297c2.54.641 4.221 2.189 4.912 4.049l-.708-3.556c-.116-.494-.474-.633-.915-.65z" />
                            </g>
                        </g>
                    </svg>
                );
        
            default:
                return (
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="currentColor" className="icon icon-tabler icons-tabler-filled icon-tabler-credit-card">
                        <path d="M22 10v6a4 4 0 0 1 -4 4h-12a4 4 0 0 1 -4 -4v-6h20zm-14.99 4h-.01a1 1 0 1 0 .01 2a1 1 0 0 0 0 -2zm5.99 0h-2a1 1 0 0 0 0 2h2a1 1 0 0 0 0 -2zm5 -10a4 4 0 0 1 4 4h-20a4 4 0 0 1 4 -4h12z" />
                    </svg>
                );
        };
    }

    const formatTimestamp = (unixTimestamp) => {
        // Ensure the input is a valid number
        if (typeof unixTimestamp !== 'number' || unixTimestamp < 0) {
            throw new Error("Invalid Unix timestamp");
        }
    
        // Create a Date object from the Unix timestamp (convert seconds to milliseconds)
        const date = new Date(unixTimestamp * 1000);
    
        // Format the date
        const dateOptions = { month: 'short', day: 'numeric' };
        const timeOptions = { hour: '2-digit', minute: '2-digit', hour12: true };
    
        // Combine the formatted date and time with a comma
        const formattedDate = date.toLocaleDateString('en-US', dateOptions);
        const formattedTime = date.toLocaleTimeString('en-US', timeOptions);
    
        return `${formattedDate}, ${formattedTime}`;
    };

    return (
        <tr className={styles.paymentItem}>
            <td className={styles.paymentItemColumn}>
                <div className={styles.amountColumn}>
                    <h5 className={styles.boldText}>{formatAmount(props.payment.amount)}</h5>
                </div>
            </td>
            <td className={styles.paymentItemColumn}>
                <div className={styles.currencyColumn}>
                    <h5 className={styles.regularText}>{props.payment.currency.toUpperCase()}</h5>
                </div>
            </td>
            <td className={styles.paymentItemColumn}>
                <div className={styles.statusColumn}>
                    {
                        formatStatus(props.payment.status)
                    }
                </div>
            </td>
            <td className={styles.paymentItemColumn}>
                <div className={styles.projectNameColumn}>
                    <h5 className={styles.regularText}>{props.payment.project_name}</h5>
                </div>
            </td>
            <td className={styles.paymentItemColumn}>
                <div className={styles.durationColumn}>
                    <h5 className={styles.regularText}>{formatDuration(props.payment.duration)}</h5>
                </div>
            </td>
            <td className={styles.paymentItemColumn}>
                <div className={styles.paymentMethodColumn}>
                    {
                        formatPaymentMethod(props.payment.payment_method)
                    }
                </div>
            </td>
            <td className={styles.paymentItemColumn}>
                <div className={styles.dateColumn}>
                    <h5 className={styles.regularText}>{formatTimestamp(props.payment.created_at)}</h5>
                </div>
            </td>
        </tr>
    )
}