import React from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './ProjectItem.module.css';

export default function ProjectItem(props) {
    const navigate = useNavigate();

    const getStateIcon = (state) => {
        switch (state) {
            case "CREATED":
            case "PROCESSING":
                return (<div className={styles.loadingCircle} />);
            case "COMPLETED":
                return (
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="#3298F1" className="icon icon-tabler icons-tabler-filled icon-tabler-player-play">
                        <path d="M6 4v16a1 1 0 0 0 1.524 .852l13 -8a1 1 0 0 0 0 -1.704l-13 -8a1 1 0 0 0 -1.524 .852z" />
                    </svg>
                );
            case "FAILED":
                return (
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="#FDEE34" className="icon icon-tabler icons-tabler-filled icon-tabler-info-triangle">
                        <path d="M12 1.67c.955 0 1.845 .467 2.39 1.247l.105 .16l8.114 13.548a2.914 2.914 0 0 1 -2.307 4.363l-.195 .008h-16.225a2.914 2.914 0 0 1 -2.582 -4.2l.099 -.185l8.11 -13.538a2.914 2.914 0 0 1 2.491 -1.403zm0 9.33h-1l-.117 .007a1 1 0 0 0 0 1.986l.117 .007v3l.007 .117a1 1 0 0 0 .876 .876l.117 .007h1l.117 -.007a1 1 0 0 0 .876 -.876l.007 -.117l-.007 -.117a1 1 0 0 0 -.764 -.857l-.112 -.02l-.117 -.006v-3l-.007 -.117a1 1 0 0 0 -.876 -.876l-.117 -.007zm.01 -3l-.127 .007a1 1 0 0 0 0 1.986l.117 .007l.127 -.007a1 1 0 0 0 0 -1.986l-.117 -.007z" />
                    </svg>
                );
            default:
                return null;
        }
    };

    const formatDuration = (seconds) => {
        // Ensure the input is a number and not negative
        if (typeof seconds !== 'number' || seconds < 0) {
            throw new Error("Input must be a non-negative number");
        }
    
        // Round to the nearest second
        const roundedSeconds = Math.round(seconds);
    
        // Calculate hours, minutes, and seconds
        const hours = Math.floor(roundedSeconds / 3600);
        const minutes = Math.floor((roundedSeconds % 3600) / 60);
        const secs = roundedSeconds % 60;
    
        // Format as "HH:MM:ss" with leading zeros
        return [
            hours.toString().padStart(2, '0'),
            minutes.toString().padStart(2, '0'),
            secs.toString().padStart(2, '0')
        ].join(':');
    };

    const formatTimestamp = (unixTimestamp) => {
        // Ensure the input is a valid number
        if (typeof unixTimestamp !== 'number' || unixTimestamp < 0) {
            throw new Error("Invalid Unix timestamp");
        }
    
        // Create a Date object from the Unix timestamp (convert seconds to milliseconds)
        const date = new Date(unixTimestamp * 1000);
    
        // Format the date
        const dateOptions = { month: 'short', day: 'numeric' };
        const timeOptions = { hour: '2-digit', minute: '2-digit', hour12: true };
    
        // Combine the formatted date and time with a comma
        const formattedDate = date.toLocaleDateString('en-US', dateOptions);
        const formattedTime = date.toLocaleTimeString('en-US', timeOptions);
    
        return `${formattedDate}, ${formattedTime}`;
    };

    const handleClick = () => {
        if (props.project.project_state === "COMPLETED") {
            navigate('/project/' + props.project.project_uuid, {
                state: {
                    project: props.project
                }
            });
        }
    };

    return (
        <tr className={styles.projectItem} onClick={handleClick}>
            <td className={styles.stateColumn}>
                <div className={styles.projectItemState}>
                    {getStateIcon(props.project.project_state)}
                </div>
            </td>
            <td className={styles.nameColumn}>
                <h4 className={styles.projectItemName}>{props.project.project_name}</h4>
            </td>
            <td className={styles.durationColumn}>
                <h5 className={styles.projectItemDuration}>{formatDuration(props.project.duration)}</h5>
            </td>
            <td className={styles.createdAtColumn}>
                <h5 className={styles.projectItemDuration}>{formatTimestamp(props.project.created_at)}</h5>
            </td>
        </tr>
    )
}