import React, { useEffect, useState } from 'react';
import styles from './Support.module.css';

import { useAuth, useInput } from '../../hooks/';
import ProjectNavbar from '../../components/ProjectNavbar';
import Sidebar from '../../components/Sidebar/Sidebar';
import DescribeVideoButton from '../../components/DescribeVideoButton/DescribeVideoButton';

export default function Support(props) {
    const auth = useAuth();

    const { value: email, bind: bindEmail, reset: resetEmail } = useInput(auth.user.email);
    const { value: supportText, bind: bindSupportText, reset: resetSupportText } = useInput("");

    const [loading, setLoading] = useState(false);
    
    const [emailError, setEmailError] = useState(null);
    const [supportTextError, setSupportTextError] = useState(null);

    useEffect(() => {
        setEmailError(null);
    }, [email]);

    useEffect(() => {
        setSupportTextError(null);
    }, [supportText]);

    const handleSubmit = () => {
        var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        if (email === "") {
            setEmailError("Email is required");
            return;
        } else if (!email.match(validRegex)) {
            setEmailError("Not a valid email");
            return;
        }

        if (supportText === "") {
            setSupportTextError("Your question is required");
        }

        setLoading(true);
    };

    return (
        <div className={styles.basePage}>
            <ProjectNavbar />
            <div className={styles.content}>
                <Sidebar selected={3} />
                <main className={styles.mainContent}>
                    <div className={styles.supportSection}>
                        <h2 className={styles.supportSectionHeader}>Support</h2>
                        <p className={styles.supportSectionText}>For any questions or issues, please email autoscribeco@gmail.com.</p>
                        {/* <div className={styles.supportForm}>
                            <div className={styles.supportFormGroup}>
                                <h5 className={styles.supportFormGroupLabelText}>Your email</h5>
                                <input className={"textInputBox" + (emailError !== null ? " red" : "")} placeholder="Email" type="text" {...bindEmail} />
                                {
                                    emailError !== null && (
                                        <div className={styles.supportErrorMessage}>
                                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-alert-circle" width="16" height="16" viewBox="0 0 24 24" strokeWidth="2" stroke="#C13515" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                <path d="M3 12a9 9 0 1 0 18 0a9 9 0 0 0 -18 0"></path>
                                                <path d="M12 8v4"></path>
                                                <path d="M12 16h.01"></path>
                                            </svg>
                                            <p className="red-regular-5-text">{emailError}</p>
                                        </div>
                                    )
                                }
                            </div>
                            <div className={styles.supportFormGroup}>
                                <h5 className={styles.supportFormGroupLabelText}>What do you need support with?</h5>
                                <textarea className={"textInputBox" + (supportTextError !== null ? " red" : "")} placeholder="Describe your question" type="text" {...bindSupportText} />
                                {
                                    supportTextError !== null && (
                                        <div className={styles.supportErrorMessage}>
                                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-alert-circle" width="16" height="16" viewBox="0 0 24 24" strokeWidth="2" stroke="#C13515" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                <path d="M3 12a9 9 0 1 0 18 0a9 9 0 0 0 -18 0"></path>
                                                <path d="M12 8v4"></path>
                                                <path d="M12 16h.01"></path>
                                            </svg>
                                            <p className="red-regular-5-text">{supportTextError}</p>
                                        </div>
                                    )
                                }
                            </div>
                            {
                                loading ? (
                                    <div className={styles.loadingCircle} />
                                ) : (
                                    <DescribeVideoButton buttonText={"Submit"} onClick={handleSubmit} />
                                )
                            }
                        </div> */}
                    </div>
                </main>
            </div>
        </div>
    );
}