import React from 'react';
import { Link } from 'react-router-dom';
import styles from './Modal.module.css';

export default function Modal(props) {
    return (
        <div className={styles.modalContainer}>
            <div className={styles.modalHeaderContainer}>
                {
                    props.headerAction === "EXIT" && (
                        <div className={styles.modalClose} onClick={() => props.exit()}>
                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-x" width="20" height="20" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#000000" fill="none" strokeLinecap="round" strokeLinejoin="round" preserveAspectRatio="none">
                                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                <line x1="18" y1="6" x2="6" y2="18" />
                                <line x1="6" y1="6" x2="18" y2="18" />
                            </svg>
                        </div>
                    )
                }
                {
                    props.headerAction === "BACK_TO_HOME" && (
                        <Link className={styles.modalLogo} to='/'>
                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-feather" width="40" height="40" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#FFFFFF" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                <path d="M4 20l10 -10m0 -5v5h5m-9 -1v5h5m-9 -1v5h5m-5 -5l4 -4l4 -4"></path>
                                <path d="M19 10c.638 -.636 1 -1.515 1 -2.486a3.515 3.515 0 0 0 -3.517 -3.514c-.97 0 -1.847 .367 -2.483 1m-3 13l4 -4l4 -4"></path>
                            </svg>
                        </Link>
                    )
                }
                <div className={styles.modalTitleContainer}>
                    <h2 className="black-header-5-text">{props.title}</h2>
                </div>
            </div>
            <div className={styles.modalContentContainer}>
                {
                    props.detail
                }
                {
                    props.loading ? (
                        <div className={styles.loadingCircle} />
                    ) : (
                        <div className={styles.modalSubmitButton} onClick={props.action}>
                            {props.actionText}
                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-chevron-right" width="16" height="16" viewBox="0 0 24 24" strokeWidth="3" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                <path d="M9 6l6 6l-6 6"></path>
                            </svg>
                        </div>
                    )
                }
                {
                    props.secondaryAction
                }
            </div>
        </div>
    );
}