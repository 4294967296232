import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './ProjectNavbar.module.css';
import DescribeVideoButton from '../DescribeVideoButton/DescribeVideoButton';
import { useAuth } from '../../hooks';
import { OrderingController } from '../ordering/OrderingController';

export default function ProjectNavbar(props) {
    const auth = useAuth();
    const navigate = useNavigate();

    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [orderingState, setOrderingState] = useState("UNSET");

    useEffect(() => {
        if (props.shouldActivateOrdering === true) {
            activateOrdering();
        }
    }, [props.shouldActivateOrdering]);

    const activateOrdering = () => {
        setOrderingState("VIDEO_SELECTION");
        document.body.style.overflow = 'hidden';
    };
    
    const deactivateOrdering = () => {
        document.body.style.overflow = 'auto';
        setOrderingState("UNSET");

        if (props.refresh && typeof props.refresh === 'function') {
            props.refresh();
        }

        if (props.shouldActivateOrdering === true && props.toggleOrderingState && typeof props.toggleOrderingState === 'function') {
            props.toggleOrderingState();
        }
    };

    
    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    const handleSignOut = () => {
        auth.handleSignOut();
        navigate('/sign-in');
    };

    return (
        <>
            <OrderingController orderingState={orderingState} deactivate={deactivateOrdering} />
            <nav className={styles.navbar}>
                <div className={styles.navbarContent}>
                    {/* Logo and Branding */}
                    <div className={styles.logoContainer}>
                        <div className={styles.logo}>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="icon icon-tabler icon-tabler-feather"
                                width="27"
                                height="27"
                                viewBox="0 0 24 24"
                                strokeWidth="1.5"
                                stroke="#FFFFFF"
                                fill="none"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            >
                                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                <path d="M4 20l10 -10m0 -5v5h5m-9 -1v5h5m-9 -1v5h5m-5 -5l4 -4l4 -4"></path>
                                <path d="M19 10c.638 -.636 1 -1.515 1 -2.486a3.515 3.515 0 0 0 -3.517 -3.514c-.97 0 -1.847 .367 -2.483 1m-3 13l4 -4l4 -4"></path>
                            </svg>
                        </div>
                        <h5 className={styles.brandName}>Autoscribe</h5>
                    </div>

                    {/* Navbar Actions */}
                    <div className={styles.actions}>
                        <DescribeVideoButton buttonText="Describe a video" onClick={activateOrdering} />
                    </div>

                    <div className={styles.userSettingsDropdown}>
                        <div className={styles.userSettingDropdownButton} onClick={toggleMenu}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 24 24" fill="currentColor" className="icon icon-tabler icons-tabler-filled icon-tabler-user">
                                <path d="M12 2a5 5 0 1 1 -5 5l.005 -.217a5 5 0 0 1 4.995 -4.783z" />
                                <path d="M14 14a5 5 0 0 1 5 5v1a2 2 0 0 1 -2 2h-10a2 2 0 0 1 -2 -2v-1a5 5 0 0 1 5 -5h4z" />
                            </svg>
                        </div>
                        {
                            isMenuOpen && (
                                <div className={styles.userSettingDropdownContent}>
                                    <p className={styles.userSettingDropdownItem} onClick={handleSignOut}>Log out</p>
                                </div>
                            )
                        }
                    </div>

                    {/* Hamburger Menu */}
                    <button
                        className={styles.hamburgerMenu}
                        onClick={toggleMenu}
                        aria-expanded={isMenuOpen}
                        aria-label="Toggle menu"
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="icon icon-tabler icon-tabler-menu-2"
                            width="20"
                            height="20"
                            viewBox="0 0 24 24"
                            strokeWidth="1.5"
                            stroke="#2c3e50"
                            fill="none"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        >
                            <path d="M4 6l16 0" />
                            <path d="M4 12l16 0" />
                            <path d="M4 18l16 0" />
                        </svg>
                    </button>
                </div>
            </nav>
        </>
    );
}