import React from 'react';
import styles from './VideoSelection.module.css';
import UploadVideo from '../../UploadVideo';

export default function VideoSelection(props) {
    const handleSubmit = (url, file) => {
        // if (url !== null) {
        //     if (!isValidUrl(url)) {
        //         setURLError("Please enter a valid URL.");
        //         return;
        //     }
        //     setURLError(null);
        // }
        props.next(file);
    };

    return (
        <div className={styles.grayModalBackground} onClick={(e) => props.exit(e)}>
            <div className={styles.orderingBase}>
                <div className={styles.modalHeaderContainer}>
                    <div className={styles.modalHeaderTitle}>
                        <h2 className="black-header-5-text">Select your video</h2>
                    </div>
                </div>
                <div className={styles.modalContentContainer}>
                    <UploadVideo activateOrdering={handleSubmit} />
                </div>
            </div>
        </div>
    );
}